	
	var presentations;
	var slides;
	var slideIndex = 0;
	let presoContentFolder = "/StreamingAssets/Unveil/Content/presentations"

	window.sendTalsarEvent = function (jsonData)
    {

    	let captureScript =  "https://script.google.com/macros/s/AKfycbzhtzJb5uLhH8e66JmR_o26sNIU3UAkNk-ziJCgcWvR3Suo4qRomHbwMztdKhZgCDdZ/exec"
		fetch(captureScript, 
			{
			  method: "POST",
			  headers: {
				"Content-Type": "application/json"
			  },
			  body: jsonData,
			  mode: "no-cors" // Enable no-cors mode
			}).then(function(response) 
			{
			  
			  //no response with no-cors
				console.log("sendTalsarEvent: " + jsonData)

			}).catch(function(error) 
			{
			  console.error("Request failed:", error);
			});
	}
    
	window.openContent = function (key="presentation", presentationTitle="Primary Assessment", userID="", sessionID="") 
	{
	
		console.log("key: " + key);
		console.log("presentationTitle: " + presentationTitle);
		window.currentPresentationTitle = presentationTitle;
		window.currentUserID = userID;
		window.currentSessionID = sessionID;

		var container = document.querySelector("#canvas");
		container.style.display = "none";
		container.style.visibility = "collapse";

		var didacticContent = document.querySelector("#didacticContent");
		didacticContent.style.display = "flex";
		didacticContent.style.visibility = "visible";
		window.presentationTitle = presentationTitle;
		
		presentations.forEach(presentation =>
		{
		
			console.log("presentationTitle: " + window.presentationTitle);
			console.log("presentation.title: " + presentation.title);

			if(presentation.title === window.presentationTitle)
			{
				slides = presentation.slides;
		
				var presentationTitle = document.querySelector("#presentationTitle");
				presentationTitle.textContent = presentation.title;
				slideIndex = 0;
		
				showSlide();
			}
		});
		
		
	}
	
	function showUnity()
	{
		//remove all videos so no playing in background
		const videos = document.querySelectorAll('video');

		videos.forEach(video => {
			video.remove();
		});

		var didacticContent = document.querySelector("#didacticContent");
		didacticContent.style.display = "none";
		didacticContent.style.visibility = "collapse";
		
		var container = document.querySelector("#canvas");
		container.style.display = "block";

		//emscripten is adding width/height attributes as 0
		container.removeAttribute("width");
		container.removeAttribute("height");

		container.style.visibility = "visible";
		  		
  		 var talsarEvent = {};
  		 talsarEvent.timestamp = Date.now();
  		 talsarEvent.userID = window.currentUserID;
		 talsarEvent.sessionID = window.currentSessionID;

  		 talsarEvent.platform = "WebGLPlayer"
    	 talsarEvent.info = []
    	 talsarEvent.type = "TOPIC_ACTIVITY_COMPLETED";
    	 var info = {}
    	 info.key = "Topic name";
    	 info.value = window.currentPresentationTitle;
		 talsarEvent.info.push(info); 
         let jsonData = JSON.stringify(talsarEvent);
         window.sendTalsarEvent(jsonData)
  		
	}
	
	function showSlide()
	{
		const imageContainer = document.querySelector('.image-container');
		const prevButton = document.querySelector('.prev');
		const nextButton = document.querySelector('.next');
		
		nextButton.style.visibility = "hidden";
		prevButton.style.visibility = "hidden";

		if (slideIndex >= slides.length)
		{
			slideIndex = 0;
		} else 
		{
			if (slideIndex < 0) 
			{
				slideIndex = slides.length - 1;
			}
		}
		
		let slide = slides[slideIndex]
		

		while (imageContainer.firstChild) 
		{
			imageContainer.removeChild(imageContainer.firstChild);
		}
		
		function addLoad(el)
		{
			var loadEvent = "load"
			
			if(el.tagName === 'VIDEO')
			{
				loadEvent = "loadedmetadata"
			}
			el.addEventListener(loadEvent, function () 
			{
			
				// Get the height of the loaded image
				const imageHeight = el.clientHeight;

				// Set the height of the previous and next buttons to match the loaded image

				if(slideIndex == 0)
				{
					prevButton.style.visibility = "hidden";
				}else
				{
					prevButton.style.visibility = "visible";
				}
	 
	
				
				prevButton.style.height = imageHeight + 'px';
				nextButton.style.height = imageHeight + 'px';
				if(slideIndex < slides.length-1)
				{
				   nextButton.style.visibility = "visible";
				}

				if(el.tagName === 'VIDEO')
				{
					el.play();
				}
			
			});
		}
		if(slide.isVideo)
		{
			const videoElement = document.createElement('video');
			videoElement.classList.add('slides');
			videoElement.autoplay = true;
			videoElement.controls = true;
			imageContainer.appendChild(videoElement);
			addLoad(videoElement)
			
			
			videoElement.src = `${presoContentFolder}/${slide.fileName}`;


		}else
		{
			const img = document.createElement('img');
			img.classList.add('slides');
			imageContainer.appendChild(img);
			addLoad(img)
			img.src = `${presoContentFolder}/${slide.fileName}`;
		} 

	}
	

	function plusSlides(n) 
	{
		slideIndex += n;
		showSlide();
	}
	
	function addSlideshowHTML(parentElement)
    {
		

        var nextArrowSVGString = `<svg xmlns="http://www.w3.org/2000/svg"
									fill="black"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									class="w-6 h-6">
									<path stroke-linecap="round"
									stroke-linejoin="round"
									d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>`


		var previousArrowSVGString = `<svg xmlns="http://www.w3.org/2000/svg"
										fill="black"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										class="w-6 h-6">
										<path stroke-linecap="round"
											stroke-linejoin="round"
											d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>`

		var navBackSVGString = `<svg xmlns="http://www.w3.org/2000/svg"
								fill="black"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								class="w-6 h-6">
								<path stroke-linecap="round"
									stroke-linejoin="round"
									d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
								</svg>`
		const parser = new DOMParser();
		const navBackSVG = parser.parseFromString(navBackSVGString, "image/svg+xml").querySelector("svg");
		const previousArrowSVG = parser.parseFromString(previousArrowSVGString, "image/svg+xml").querySelector("svg");
		const nextArrowSVG = parser.parseFromString(nextArrowSVGString, "image/svg+xml").querySelector("svg");

		
		
        var slideshowNavbar = document.createElement("div");
		slideshowNavbar.id = "slideshowNavbar"
		
		var presentationTitle = document.createElement("div");
		presentationTitle.textContent = "PRESO TITLE";
		presentationTitle.id = "presentationTitle"
		
		var slideshowBackButton = document.createElement("div");
		slideshowBackButton.id = "slideshowBackButton";
		slideshowBackButton.appendChild(navBackSVG);
		slideshowNavbar.appendChild(slideshowBackButton);
		
		slideshowNavbar.appendChild(presentationTitle);

		slideshowBackButton.onclick = showUnity;
		
		var prevButtonContainer = document.createElement("div");
		prevButtonContainer.classList.add("prev");
		var prevButton = document.createElement("div");
		
		
		prevButton.appendChild(previousArrowSVG) 
		
		prevButton.onclick = function() 
		{
            plusSlides(-1);
        };
        prevButton.style.width = "100px";
		prevButtonContainer.appendChild(prevButton);
		
		
 		var nextButtonContainer = document.createElement("div");
		nextButtonContainer.classList.add("next");
		
		var nextButton = document.createElement("div");

		nextButton.appendChild(nextArrowSVG)
		nextButton.onclick = function() 
		{
			plusSlides(1);
        };

		nextButton.style.width = "100px";
		nextButtonContainer.appendChild(nextButton);
		
        var slideshowContainer = document.createElement("div");
        slideshowContainer.classList.add("slideshow-container");

        var imageContainer = document.createElement("div");
        imageContainer.classList.add("image-container");

        // Append buttons and image container to the .slideshow-container
        slideshowContainer.appendChild(prevButtonContainer);
        slideshowContainer.appendChild(imageContainer);
        slideshowContainer.appendChild(nextButtonContainer);

        // Get the #content element and append all elements
        var didacticContent = document.createElement("div");
		didacticContent.id = "didacticContent";

        didacticContent.appendChild(slideshowNavbar);
        didacticContent.appendChild(slideshowContainer);
		parentElement.appendChild(didacticContent);

    }
    
    document.addEventListener("DOMContentLoaded", function() 
    {
    	let presentationJSONFile = `${presoContentFolder}/presentations.json`
		fetch(presentationJSONFile)
        .then(response => response.text())
        .then(jsonString => {
          let jsonObject = JSON.parse(jsonString);
          presentations = jsonObject.presentations;
		  console.log(jsonObject);
          addSlideshowHTML(document.body);
        });
    });
    
    
    
    
    
	
	


	

