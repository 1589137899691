import './talsar.js';

interface UnityConfig {
    dataUrl: string,
    frameworkUrl: string,
    codeUrl: string;
    memoryUrl?: string;
    symbolsUrl?: string;
    streamingAssetsUrl: string;
    companyName: string;
    productName: string;
    productVersion: string;
}

interface UnityInstance {
    SetFullscreen: (fullscreen: 1 | 0) => void;
    SendMessage: (objectName: string, methodName: string, value?: string | number) => void;
    Quit: () => Promise<void>;
}

// These are declared externally
declare function createUnityInstance(canvas: HTMLCanvasElement, config: UnityConfig, onProgress?: (progress: number) => void): Promise<UnityInstance>;
declare var config: UnityConfig;

async function loadUnity(): Promise<void> {
    if (!globalThis.createUnityInstance) {
        console.error('Loader appears to have failed');
        return;
    }
    const progressArea = document.querySelector<HTMLDivElement>('.progress-area');
    progressArea.classList.add('progress-area--active');

    const progress = progressArea.querySelector('progress');
    await globalThis.createUnityInstance(
        document.querySelector('#canvas'),
        globalThis.config,
        p => progress.value = p,
    );
    progressArea.remove();
}

const beginButton = document.querySelector<HTMLButtonElement>('#begin-button');
const click = document.querySelector<HTMLAudioElement>('#click');

beginButton.addEventListener('click', _ => {
    click.play();
});

click.addEventListener('ended', _ => {
    document.querySelector('#begin-prompt').remove();
    click.remove();
    loadUnity();
});

beginButton.disabled = false;
